<template>
	<div class="flex-1 bg-white pa-24 radius-20">

		<div class="under-line-bbb pb-16 flex-row">
			<h3 class="flex-1 size-px-20">{{ program.title }}</h3>
		</div>
		<div class="mt-25">
			<div class="text-center">
				<div class="img-box-100 ma-auto radius-12">
					<img :src="item_plan.payment_subscrp_plan_img_url" @error="$bus.$emit('onErrorImage', $event)" class="object-cover" />
				</div>

				<div
					v-if="item_plan.payment_subscrp_plan_number"
					class="mt-10"
				>
					<div class="mt-10 font-weight-500 size-px-16 line-height-140">{{ item_plan.payment_subscrp_plan_name }}</div>
					<div class="mt-10 size-px-16 font-weight-600 color-blue-3e flex-row justify-center align-center gap-5"><img :src="require('@/assets/image/icon_won_blue.svg')" /><span class="line-height-1"> {{ item_plan.payment_subscrp_plan_price | makeComma }}원</span></div>
					<div class="mt-10 size-px-13 color-mafia-blue">
						{{ item_plan.payment_subscrp_enddate.slice(0, 10) }}
						{{ $language.cartel_new.title_finish }} <!-- 종료 -->
					</div>
				</div>
				<div
					v-else
					class="mt-20 size-px-16 font-weight-500"
				>{{ $language.cartel_new.error_not_subscribe_plan }}<!-- 이용중인 구독 플랜이 없습니다.--></div>
			</div>
			<ul class="mt-25 top-line pt-20">
				<li
					v-for="(plane, p_index) in plane_list"
					:key="'plane_' + p_index"
					class="justify-space-between mb-20 cursor-pointer"
					@click="toDetail(plane)"
				>
					<div class="img-box-100 mr-15 radius-12 overflow-hidden"><img :src="plane.subscrp_plan_img_url" @error="$bus.$emit('onErrorImage', $event)" class="object-cover"/></div>
					<div class="flex-1 flex-column justify-center size-px-14 text-left">
						<div class="size-px-16 font-weight-700 line-height-140">{{ plane.subscrp_plan_name }}</div>
						<div class="mt-10 size-px-16 color-blue-3e font-weight-600 flex-row align-center gap-5">
							<img :src="require('@/assets/image/icon_won_blue.svg')" />
							<span class="line-height-1">
								{{ plane.subscrp_plan_price | makeComma }}원
							</span>
							<span class="size-px-13 font-weight-400 color-mafia-blue"> / 월 (VAT별도)</span>
						</div>
						<div
							v-if="plane.is_history"
							class="mt-10 color-blue-mafia size-px-13"
						>{{ $language.cartel_new.txt_subscribe_history_ok }} <!-- 구독 내역이 있습니다. --></div>
						<div
							v-else
							class="mt-10 color-gray size-px-13"
						>{{ $language.cartel_new.txt_subscribe_history_no }} <!-- 구독 내역이 없습니다. --></div>
					</div>
					<div
						v-if="plane.is_history"
						class="flex-column justify-center"
					>
						<button
						><v-icon>mdi-chevron-right</v-icon></button>
					</div>
				</li>
			</ul>
		</div>
		<CartelSubscribe
			v-if="is_on_subscribe"
			:user="user"
			:plan_info="item_plan"

			@cancel="offSubscribe"
		></CartelSubscribe>
	</div>
</template>

<script>
import CartelSubscribe from "@/view/SubscribeCartel/CartelSubscribe";
export default {
	name: 'SubscribePlane'
	, components: {CartelSubscribe}
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: this.$language.cartel_new.title_subscribe_history // 구독 내역
				, title: this.$language.cartel_new.title_subscribe_history // 구독 내역
				, not_footer: true
				, not_header: true
				, type: 'cartel_sub'
				, bg_contents: 'bg-gray01'
				, cartel: ''
				, is_side: true

			}
			, item_cartel: {}
			, item_plan: {}
			, items_plane: []
			, is_on_subscribe: false
		}
	}
	, computed: {
		plane_list: function(){
			return this.items_plane.filter( (item) => {
				item.is_history = false

				if(item.subscrp_particulars_fg == 'Y'){
					item.is_history = true
				}

				return item
			})
		}
	}
	, methods: {
		getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
					this.program.cartel = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_subscribe_user_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
					}
					, type: true
				})

				if(result.success){
					this.item_plan = result.data.current_subscrp_plan
					this.items_plane = result.data.cartl_member_subscrp_plan_list
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, toDetail: function(item){
			if(item.is_history){
				this.$bus.$emit('to', { name: 'CartelSubscribeHistory', params: { idx: this.$route.params.idx, p_id: item.subscrp_plan_number, type: item.subscrp_particulars_fg}})
			}
		}
		, onSubscribe: function(){
			this.is_on_subscribe = true
		}
		, offSubscribe: function(){
			this.is_on_subscribe = false
		}
		, getPlane: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_subscribe_payment
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, subscrp_plan_number: this.$route.params.p_id
					}
					, type: true
				})

				if(result.success){
					this.item_plan = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	, async created() {
		await this.getCartel()
		await this.getData()
		await this.$bus.$emit('onLoad', this.program)
	}
}
</script>

<style>
.color-primary { color: var(--blue01) !important;}

</style>